!function(window) {
require('../node_modules/bootstrap-sass/assets/javascripts/bootstrap.min.js');

(function ($) {

  jQuery(document).ready(function () {

    function viewport() {
      var e = window,
        a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      return {
        width: e[a + 'Width'],
        height: e[a + 'Height']
      };
    }

    // make all given objects the same height across multiple rows (automated columns number detection)
    $.fn.equalHeightMultirowColumns = function () {
      var colWidth = $(this).eq(0).outerWidth(true);
      var parentWidth = $(this).eq(0).parent().outerWidth();
      var nCols = parseInt(parentWidth / colWidth);
      $(this).equalHeightNRowColumns(nCols);
    }

    // make all given objects in the same row of equal height, across N columns
    $.fn.equalHeightNRowColumns = function (nCols) {
      var elmnts = $(this);
      var nElements = elmnts.length;

      var aHeights = new Array();

      var i = 0;
      var cnt = 0;
      var tallest = 0;
      elmnts.each(function () {
        var el = $(this);

        if ($(this).outerHeight(true) > tallest) {
          tallest = $(this).outerHeight(true);
        }

        if (cnt == nCols - 1) {
          aHeights.push(tallest);
          tallest = 0;
          cnt = 0;
        } else {
          if (i == nElements - 1) {
            aHeights.push(tallest);
            tallest = 0;
          }
          cnt++;
        }

        i++;
      });

      cnt = 0;
      var arrCnt = 0;
      elmnts.each(function () {
        var el = $(this);

        var diff = aHeights[arrCnt] - el.outerHeight(true);
        el.height(el.height() + diff);

        if (cnt < nCols - 1) {
          cnt++;
        } else {
          cnt = 0;
          arrCnt++;
        }
      });
    };

    // remove any previously set equal heights

    $.fn.removeEqualHeights = function () {
      $(this).each(function () {
        $(this).css('height', '');
      });
    };
    // END make all given objects in the same row of equal height, across N columns

    $('img[typeof="foaf:Image"]').addClass('img-responsive');

    $('.view-anniversary-video .view-content .item-wrapper').each(function (index) {

      if (index % 5 == 0) {
        $(this).addClass("col-sm-offset-1");
      }
      index += 1;
    });

    setTimeout(function () {
      $('#anniversary-logo').fadeIn(2000).animate({
        "top": "150",
        "max-width": "150",
      }, 3000);;
    }, 1000);

    function mapNavs() {
      $('.mapNavs a').click(function (ev) {
        ev.preventDefault();
      });

      var tab = ('.nav-tabs .tabB')
      var m = $('#memb');
      var f = $('#fell');
      var fe = $('#fellEcws');
      var a = $('#aww');
      var gr = $('#pall');

      var mapView = $('.front .view--entry-poligons');
      var mapM = $('.view--entry-poligons.view-display-id-block_2');
      var mapF = $('.view--entry-poligons.view-display-id-block_3');
      var mapEcws = $('.view--entry-poligons.view-display-id-block_6');
      var mapA = $('.view--entry-poligons.view-display-id-block_4');
      //var mapAlum = $( '.view--entry-poligons.view-display-id-block_9');
      var mapAlum = $('.view--entry-poligons.view-display-id-block_12');

      var titleMap = $('#block-views-entry-poligons-block-2 .view-header h3')
      var tMembers = $('h3.memberstitle');
      var tphdf = $('h3.phdf');
      var tecwsf = $('h3.ecwsf');
      var tawardees = $('h3.awardees');
      var tpalum = $('h3.palum');

      $(tab).removeClass('active');
      m.addClass('active');
      mapView.css({
        'opacity': '0',
        'height': '0'
      });

      mapM.css({
        'opacity': '1',
        'height': 'auto'
      });

      titleMap.hide();
      tMembers.show();


      $(f).click(function () {
        $(tab).removeClass('active');
        $(f).addClass('active');
        mapView.css({
          'opacity': '0',
          'height': '0'
        });
        mapF.css({
          'opacity': '1',
          'height': 'auto'
        });
        titleMap.hide();
        tphdf.show();
      });

      $(a).click(function () {
        $(tab).removeClass('active');
        $(a).addClass('active');
        mapView.css({
          'opacity': '0',
          'height': '0'
        });
        mapA.css({
          'opacity': '1',
          'height': 'auto'
        });
        titleMap.hide();
        tawardees.show();
      });

      $(m).click(function () {
        $(tab).removeClass('active');
        $(m).addClass('active');
        mapView.css({
          'opacity': '0',
          'height': '0'
        });
        mapM.css({
          'opacity': '1',
          'height': 'auto'
        });
        titleMap.hide();
        tMembers.show();
      });

      $(fe).click(function () {
        $(tab).removeClass('active');
        $(fe).addClass('active');
        mapView.css({
          'opacity': '0',
          'height': '0'
        });
        mapEcws.css({
          'opacity': '1',
          'height': 'auto'
        });

        titleMap.hide();
        tecwsf.show();
      });

      $(gr).click(function () {
        $(tab).removeClass('active');
        $(gr).addClass('active');
        mapView.css({
          'opacity': '0',
          'height': '0'
        });
        mapAlum.css({
          'opacity': '1',
          'height': 'auto'
        });

        titleMap.hide();
        tpalum.show();
      });


    }
    mapNavs();

    $('.count').counterUp({
      //delay: 10,
      time: 2000,
    });
    $('.count2').counterUp({
      //delay: 10,
      time: 2000,
    });

    function frontgridColor() {
      $('.become-member-wrapper .link-box').each(function () {
        var color = $(this).attr("color-data");
        $(this).css('background-color', color);
      });
    }
    //frontgridColor();

    function eqh() {
      var allMembers = $('.allmembers');
      if (allMembers.length) {
        allMembers.removeEqualHeights();
        allMembers.equalHeightMultirowColumns();
      }
      var accordionButton = $('.accordion .accordion-button').not('.allmembersnew');
      if (accordionButton.length) {
        accordionButton.removeEqualHeights();
        accordionButton.equalHeightMultirowColumns();
      }

      var opportunities = $('.opportunities');
      if (opportunities.length) {
        opportunities.removeEqualHeights();
        opportunities.equalHeightMultirowColumns();
      }
    }
    eqh();

    $('.network-info-wrapper').each(function (index) {
      var $this = $(this);
      var $chart = $('.chart', $this);
      var chartData = $chart.data('chart-data');

      $chart.highcharts(Drupal.pieChartOptions(chartData));
    });

    function hcCountry() {
      $('.network-full-info').each(function (index) {
        var $this = $(this);
        var $chart = $('.network-chart', $this);
        var chartData = $chart.data('chart-data');

        $chart.highcharts(Drupal.pieChartOptions(chartData));
      });
    }
    hcCountry();


    $('[data-toggle="tooltip"]').tooltip();

    function regionsMembers() {
      $('.view--front-members .member-short figure img').addClass('img-thumbnail');
      $('.view--front-members .member-short figure').each(function () {
        var w = $(this).width();
        var h = $(this).height();
        console.log(w + ',' + h);
        if (h < w) {
          $('.view--front-members .member-short figure img').height('auto');
        } else {
          $('.view--front-members .member-short figure img').height(w);
          $('.view--front-members .member-short.member-short-success figure img').height(w - 6);
        }

        $(this).width(w);
        $(this).height(w);
      });

    }
    //regionsMembers();

    function regionsMembers2() {
      $('.view-front-regions-country-list .member-short').removeClass('col-sm-2').addClass('col-sm-3');
      $('.view-front-regions-country-list .member-short figure img').addClass('img-thumbnail');
      $('.view-front-regions-country-list .member-short figure').each(function () {
        var w = $(this).width();
        var wp = $('.view-front-regions-country-list .member-short').width();
        $('.view-front-regions-country-list .member-short figure img').height(wp);
        $('this').width(wp);
      });

    }
    //regionsMembers2();

    $('nav li.expanded').hover(function () {
      var self = $(this);
      $('ul', self).fadeIn(10);
    }, function () {
      var self = $(this);
      $('ul', self).delay(50).fadeOut(10);
    });

    // filter vstopna stran ajax
    $(document).ajaxComplete(function (e, xhr, settings) {
      if (settings.url == Drupal.settings.basePath + "?q=views/ajax" || settings.url.indexOf(Drupal.settings.basePath + "views/ajax") === 0 || settings.url == Drupal.settings.basePath + "?q=system/ajax" || settings.url == Drupal.settings.basePath + "system/ajax") {
        // enable change
        $('body').imagesLoaded(function () {
          eqh();
          $('[data-toggle="tooltip"]').tooltip();
          hcCountry();
          $('#content .node-member_profile_alumna-form td .form-submit').hide();
          membersIcons();

        });
      }
    });

    var menuItem = $('.secondarymenu-wrapper .block-menu-block .menu-block-wrapper > ul > li');
    var manuRatio = 100 / menuItem.size();
    menuItem.css('width', manuRatio + '%');

    if ($('#conference').length) {
      $('.members .item-list').each(function () {
        $('.members .item-list li').addClass('col-sm-6')
      });
    }


    function hcCountry() {
      $('.network-full-info').each(function (index) {
        var $this = $(this);
        var $chart = $('.network-chart', $this);
        var chartData = $chart.data('chart-data');

        $chart.highcharts(Drupal.pieChartOptions(chartData));
      });
    }
    hcCountry();

    /*if($('.gallery-news').length) {
    	$('.cboxElement').each(function (index,item) {
    		var $this = $(item);
    		var $thisImg = $this.find( 'img' );
    		var imgSrc = $thisImg.attr('src');
    		$thisImg.css('opacity','0');
    		$this.wrap( '<div class="col-md-3 col-sm-4 col-xs-6 text-center galleryitem" style="background-image:url(' + imgSrc + ')"></div>' );
    		$this.wrap( '<div class="galleryitem grid-item"></div>' );
    	});
    }*/

    if ($('.gallery-news').length) {
      $('.cboxElement').each(function (index, item) {
        var $this = $(item);
        $this.wrap('<div class="galleryitem grid-item"></div>');
      });
      var $grid = $('.gallery-news .grid').isotope({
        itemSelector: '.grid-item',
        percentPosition: true,

        masonry: {
          // use outer width of grid-sizer for columnWidth
          columnWidth: '.grid-sizer',
          gutter: 10
        }
      });

      $grid.imagesLoaded().progress(function () {
        $grid.isotope('layout');
      });
    }

    function galleryOther() {
      if ($('.gallery-other').length) {
        $('.gallery-other img').each(function (index, item) {
          var $this = $(item);
          $this.wrap('<div class="grid-item"></div>');
        });
        //var $grid = $('.gallery-other .grid');
        var $grid = $('.gallery-other .grid').isotope({
          itemSelector: '.grid-item',
          percentPosition: true,

          masonry: {
            // use outer width of grid-sizer for columnWidth
            columnWidth: '.grid-sizer',
            gutter: 10
          }
        });

        $grid.imagesLoaded().progress(function () {
          $grid.isotope('layout');
        });
      }
    }
    var clicks = 0;
    $('.accordion-button.icon-hat').click(function () {
      if (clicks == 0) {
        galleryOther();
      }
      ++clicks;
      console.log(clicks);
    });



    if ($('.gallery-other').length) {
      $('.gallery-other img').each(function (index, item) {
        var $this = $(item);
        $this.wrap('<div class="grid-item"></div>');
      });
      //var $grid = $('.gallery-other .grid');
      var $grid = $('.gallery-other .grid').isotope({
        itemSelector: '.grid-item',
        percentPosition: true,

        masonry: {
          // use outer width of grid-sizer for columnWidth
          columnWidth: '.grid-sizer',
          gutter: 10
        }
      });

      $grid.imagesLoaded().progress(function () {
        $grid.isotope('layout');
      });
    }

    function membersIcons() {
      var wrapper = $('.allmembersnew .memberlist-item');
      wrapper.each(function (index, item) {
        var $this = $(item);
        var h = $this.height();
        var $child = $this.find('.text');
        //	$child.height(h);
        var icons = $this.find('.text div[data-toggle=tooltip]');
        if (icons.length) {
          icons.wrapAll('<div class="icons"></div>');
          //$('[data-toggle="tooltip"]').tooltip();
        }

      });

    }
    membersIcons();

    function channelIframe() {

      if (viewport().width > 960) {
        $('.block-youtubechannel').css('padding', '0 100px');
      } else {
        $('.block-youtubechannel').css('padding', '0 15px');
      }
      $("iframe#youtubechannel-frame").removeAttr('width').removeAttr('height').wrap("<div class='embed-responsive embed-responsive-4by3'/>").addClass('embed-responsive-item');
      $("#youtubechannel-frame").after('<div class="clearfix"></div>');
    }
    channelIframe();


    $('.members-new-wrapper').on('click', "#nc-reset", function (ev) {
      ev.preventDefault();
      location.reload(true);
    });

    if ($('#toolbar').length) {
      //var pt = parseInt($("body").css("padding-top"));
      var toolbarH = $('#toolbar').height();
      var headerH = $('header.main').height();
      var offset = toolbarH + headerH;
      $('.mega-menu').css('top', offset + 10);
      $('.submenu').css('top', offset + 10);
      //$('header.main').css('padding-top',pt);
    } else {
      var headerH = $('header.main').height();
      $('.mega-menu').css('top', headerH + 10);
      $('.submenu').css('top', headerH + 10);
    }

    function header() {
      var pt = parseInt($("body").css("padding-top"));
      var wrapperOffset = $(".slideout-panel").offset().top;
      //console.log(wrapperOffset);
      $('#burger-menu-nav').css('top', wrapperOffset);
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 80) {
          $('header.main').addClass('fixed').css('padding-top', pt + 10);
        } else {
          $('header.main').removeClass('fixed').css('padding-top', '10px');;
        }
      });
    }
    header();



    function newMenu() {
      var firstLevel = $('ul.first-level li a.open-menu:not(.button-nav-item)');


      $(firstLevel).each(function (index, item) {
        $(item).on("click", function (event) {
          event.preventDefault();
          if ($(item).next().is(':visible')) {
            $(item).next().delay(100).slideUp();
          } else {
            $('.mega-menu').slideUp();
            $(item).next().slideDown();
          }
        });
        $('.mega-menu').mouseleave(function () {
          $(this).delay(500).slideUp();
        });

      });

    }
    newMenu();

    function ncHeight() {
      if ($('.has-national-chapter').length) {
        var sh = $('header .submenu').height();
        var nch = $('.national-chapter-info .nc-data').height() + 150;
        var ncheader = $('.nc-header-wrapper').height();

        var h = nch + sh;
        console.log(ncheader + ', ' + nch);
        if (ncheader <= nch) {
          $('.nc-header-wrapper').height(h);
        } else {
          $('.nc-header-wrapper').removeAttr('style').css('min-height', nch + 'px');
        }


      }
    }
    ncHeight();

    if ($('.affiliation').length) {
      $('.affiliation').insertAfter($('.append-affiliation'));
    }

    if ($('#youtube').length) {


      $.ajax({
        url: 'https://www.googleapis.com/youtube/v3/channels',
        type: 'GET',
        dataType: 'json',
        data: {
          'part': 'contentDetails,status',
          'id': 'UC2zotkPMja0ox8iHLRHkDjQ,UCBVOmXyb4uFWkQyC-c3L-Lw',
          'key': 'AIzaSyCvKM4Y2MgZWoyOUxNjbACrX-hF2doLKwI'
        },
        success: function (data) {
          var uploads = data.items[0].contentDetails.relatedPlaylists.uploads;
          getVideos(uploads, '');
        }
      });

      //Use "uploads" value from previous ajax call to retrieve videos
      function getVideos(uploads, pageToken) {
        //var limit = 100;
        //Second Ajax call
        var playlistIdClass = $('#youtube').attr('class');
        if (playlistIdClass == 'global') {
          var playlistId = 'PLFWQfy1-z-jVGucsuLI8Q-j4kBl8E_wu5'
        } else {
          var playlistId = 'PLFWQfy1-z-jUHjp7D5AqsZElBd73GCk5F'
        }

        $.ajax({
          url: 'https://www.googleapis.com/youtube/v3/playlistItems',
          //url:'https://www.googleapis.com/youtube/v3/search',
          type: 'GET',
          dataType: 'json',
          data: {
            'part': 'snippet,status,contentDetails',
            'playlistId': playlistId,
            'order': 'date',
            'safeSearch': 'none',
            /*'chart': 'mostPopular',*/
            'maxResults': 9,
            'pageToken': pageToken,
            'key': 'AIzaSyCvKM4Y2MgZWoyOUxNjbACrX-hF2doLKwI'
          },
          success: function (data) {
            var prevToken = data.prevPageToken;
            var nextToken = data.nextPageToken;
            $("#youtube").empty();

            for (var i = 0; i < data.items.length; i++) {

              var formattedDate = '';
              //if(data.items[i].snippet.thumbnails) {
              var formattedDate = new Date(data.items[i].contentDetails.videoPublishedAt);
              var d = formattedDate.getDate();
              var m = formattedDate.getMonth();

              //m += 1;  // JavaScript months are 0-11
              //console.log(m);
              var y = formattedDate.getFullYear();
              const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ];
              const monthName = months[m];

              var fullDate = monthName + " " + d + ", " + y;
              //console.log(data.items[i].snippet.publishedAt);
              // var dateData = data.items[i].snippet.publishedAt


              if (data.items[i].snippet.thumbnails) {
                var title = $("<h3>").append(data.items[i].snippet.title);
                var date = $("<p>").append(fullDate);
                var thumb = $("<img>").attr("src", data.items[i].snippet.thumbnails.high.url);
                var thumb2 = $("<div class='bg-image-yt'>").attr("style", 'background-image:url(' + data.items[i].snippet.thumbnails.high.url + ');');
              } else {
                var title = $("<h3>").append('PRIVATE VIDEO<br /><small>Sign in if you\'ve been granted access to this video</small>');
                var date = $("<p>").append('');
                var thumb = $("<img>").attr("src", "/sites/all/themes/owsd/images/logo-green-yt.jpg");
                var thumb2 = $("<div class='bg-image-yt'>").attr("style", "background-image:url(/sites/all/themes/owsd/images/logo-green-yt.jpg);");
              }
              //var thumb = $("<img>").attr("src",data.items[i].snippet.thumbnails.medium.url);
              var video_id = data.items[i].snippet.resourceId.videoId;
              var video_url = 'https://www.youtube.com/watch?v=' + video_id;
              var link = $("<a target='_blank'>").attr("href", video_url).append(thumb2);
              var titlewrapperInner = $("<div class='data-wrapper'>").append(date, title);
              var titlewrapper = $("<div class='link-box news-type'>").append(titlewrapperInner);
              var holder = $("<div class='item vidCard col-sm-6 col-md-4'>").append(link, titlewrapper);

              $("#youtube").append(holder);
              //}

            }


            $('.pagination-owsd').empty();
            if (prevToken !== undefined) {
              let paginationFirst = '<li data-uploads="' + uploads + '" data-token="' + prevToken + '"class="yt-page-item prevToken">‹ prev</li>';
              $('.pagination-owsd').append(paginationFirst);
            }

            if (nextToken !== undefined) {
              let paginationLast = '<li data-uploads="' + uploads + '" data-token="' + nextToken + '"class="yt-page-item nextToken">next ›</li>';
              $('.pagination-owsd').append(paginationLast);
            }
            prevNextYt();
          }
        });
      }

      function prevNextYt() {
        $('.yt-page-item').click(function () {
          var token = $(this).attr('data-token');
          var uploads = $(this).attr('data-uploads');
          getVideos(uploads, token);
        });
      }
      prevNextYt();
    } //end youtube

    if ($('#youtube-global').length) {


      $.ajax({
        url: 'https://www.googleapis.com/youtube/v3/channels',
        type: 'GET',
        dataType: 'json',
        data: {
          'part': 'contentDetails,status',
          'id': 'UC2zotkPMja0ox8iHLRHkDjQ,UCBVOmXyb4uFWkQyC-c3L-Lw',
          'key': 'AIzaSyCvKM4Y2MgZWoyOUxNjbACrX-hF2doLKwI'
        },
        success: function (data) {
          var uploads = data.items[0].contentDetails.relatedPlaylists.uploads;
          getVideos(uploads, '');
        }
      });

      //Use "uploads" value from previous ajax call to retrieve videos
      function getVideos(uploads, pageToken) {
        //var limit = 100;
        //Second Ajax call
        var playlistIdClass = $('#youtube-global').attr('class');
        var playlistId = 'PLFWQfy1-z-jVGucsuLI8Q-j4kBl8E_wu5'

        $.ajax({
          url: 'https://www.googleapis.com/youtube/v3/playlistItems',
          //url:'https://www.googleapis.com/youtube/v3/search',
          type: 'GET',
          dataType: 'json',
          data: {
            'part': 'snippet,status,contentDetails',
            'playlistId': playlistId,
            'order': 'date',
            'safeSearch': 'none',
            /*'chart': 'mostPopular',*/
            'maxResults': 9,
            'pageToken': pageToken,
            'key': 'AIzaSyCvKM4Y2MgZWoyOUxNjbACrX-hF2doLKwI'
          },
          success: function (data) {
            var prevToken = data.prevPageToken;
            var nextToken = data.nextPageToken;
            $("#youtube-global").empty();

            for (var i = 0; i < data.items.length; i++) {

              var formattedDate = '';
              //if(data.items[i].snippet.thumbnails) {
              var formattedDate = new Date(data.items[i].contentDetails.videoPublishedAt);
              var d = formattedDate.getDate();
              var m = formattedDate.getMonth();

              //m += 1;  // JavaScript months are 0-11
              //console.log(m);
              var y = formattedDate.getFullYear();
              const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ];
              const monthName = months[m];

              var fullDate = monthName + " " + d + ", " + y;
              //console.log(data.items[i].snippet.publishedAt);
              // var dateData = data.items[i].snippet.publishedAt


              if (data.items[i].snippet.thumbnails) {
                var title = $("<h3>").append(data.items[i].snippet.title);
                var date = $("<p>").append(fullDate);
                var thumb = $("<img>").attr("src", data.items[i].snippet.thumbnails.high.url);
                var thumb2 = $("<div class='bg-image-yt'>").attr("style", 'background-image:url(' + data.items[i].snippet.thumbnails.high.url + ');');
              } else {
                var title = $("<h3>").append('PRIVATE VIDEO<br /><small>Sign in if you\'ve been granted access to this video</small>');
                var date = $("<p>").append('');
                var thumb = $("<img>").attr("src", "/sites/all/themes/owsd/images/logo-green-yt.jpg");
                var thumb2 = $("<div class='bg-image-yt'>").attr("style", "background-image:url(/sites/all/themes/owsd/images/logo-green-yt.jpg);");
              }
              //var thumb = $("<img>").attr("src",data.items[i].snippet.thumbnails.medium.url);
              var video_id = data.items[i].snippet.resourceId.videoId;
              var video_url = 'https://www.youtube.com/watch?v=' + video_id;
              var link = $("<a target='_blank'>").attr("href", video_url).append(thumb2);
              var titlewrapperInner = $("<div class='data-wrapper'>").append(date, title);
              var titlewrapper = $("<div class='link-box news-type'>").append(titlewrapperInner);
              var holder = $("<div class='item vidCard col-sm-6 col-md-4'>").append(link, titlewrapper);

              $("#youtube-global").append(holder);
              //}

            }


            $('.pagination-list').empty();
            if (prevToken !== undefined) {
              let paginationFirst = '<li data-uploads="' + uploads + '" data-token="' + prevToken + '"class="yt-page-item-global prevToken">‹ prev</li>';
              $('.pagination-list').append(paginationFirst);
            }

            if (nextToken !== undefined) {
              let paginationLast = '<li data-uploads="' + uploads + '" data-token="' + nextToken + '"class="yt-page-item-global nextToken">next ›</li>';
              $('.pagination-list').append(paginationLast);
            }
            prevNextYt();
          }
        });
      }

      function prevNextYt() {
        $('.yt-page-item-global').click(function () {
          var token = $(this).attr('data-token');
          var uploads = $(this).attr('data-uploads');
          getVideos(uploads, token);
        });
      }
      prevNextYt();
    } //end youtube


    if ($('#youtube-sidebar').length) {


      $.ajax({
        url: 'https://www.googleapis.com/youtube/v3/channels',
        type: 'GET',
        dataType: 'json',
        data: {
          'part': 'contentDetails,status',
          'id': 'UC2zotkPMja0ox8iHLRHkDjQ,UCBVOmXyb4uFWkQyC-c3L-Lw',
          'key': 'AIzaSyCvKM4Y2MgZWoyOUxNjbACrX-hF2doLKwI'
        },
        success: function (data) {
          var uploads = data.items[0].contentDetails.relatedPlaylists.uploads;
          getVideos(uploads, '');
        }
      });

      //Use "uploads" value from previous ajax call to retrieve videos
      function getVideos(uploads, pageToken) {
        //var limit = 100;
        //Second Ajax call
        var playlistIdClass = $('#youtube-sidebar').attr('class');
        var playlistId = 'PLFWQfy1-z-jVK10OXKZu3KY72qvg4a4Jc';

        $.ajax({
          url: 'https://www.googleapis.com/youtube/v3/playlistItems',
          //url:'https://www.googleapis.com/youtube/v3/search',
          type: 'GET',
          dataType: 'json',
          data: {
            'part': 'snippet,status,contentDetails',
            'playlistId': playlistId,
            'order': 'date',
            'safeSearch': 'none',
            /*'chart': 'mostPopular',*/
            'maxResults': 2,
            'pageToken': pageToken,
            'key': 'AIzaSyCvKM4Y2MgZWoyOUxNjbACrX-hF2doLKwI'
          },
          success: function (data) {
            var prevToken = data.prevPageToken;
            var nextToken = data.nextPageToken;
            $("#youtube-sidebar").empty();

            for (var i = 0; i < data.items.length; i++) {

              var formattedDate = '';
              //if(data.items[i].snippet.thumbnails) {
              var formattedDate = new Date(data.items[i].contentDetails.videoPublishedAt);
              var d = formattedDate.getDate();
              var m = formattedDate.getMonth();

              //m += 1;  // JavaScript months are 0-11
              //console.log(m);
              var y = formattedDate.getFullYear();
              const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
              ];
              const monthName = months[m];

              var fullDate = monthName + " " + d + ", " + y;
              //console.log(data.items[i].snippet.publishedAt);
              // var dateData = data.items[i].snippet.publishedAt


              if (data.items[i].snippet.thumbnails) {
                var title = $("<h3>").append(data.items[i].snippet.title);
                var date = $("<p>").append(fullDate);
                var thumb = $("<img>").attr("src", data.items[i].snippet.thumbnails.high.url);
                var thumb2 = $("<div class='bg-image-yt'>").attr("style", 'background-image:url(' + data.items[i].snippet.thumbnails.high.url + ');');
              } else {
                var title = $("<h3>").append('PRIVATE VIDEO<br /><small>Sign in if you\'ve been granted access to this video</small>');
                var date = $("<p>").append('');
                var thumb = $("<img>").attr("src", "/sites/all/themes/owsd/images/logo-green-yt.jpg");
                var thumb2 = $("<div class='bg-image-yt'>").attr("style", "background-image:url(/sites/all/themes/owsd/images/logo-green-yt.jpg);");
              }
              //var thumb = $("<img>").attr("src",data.items[i].snippet.thumbnails.medium.url);
              var video_id = data.items[i].snippet.resourceId.videoId;
              var video_url = 'https://www.youtube.com/watch?v=' + video_id;
              var link = $("<a target='_blank'>").attr("href", video_url).append(thumb2);
              var titlewrapperInner = $("<div class='data-wrapper'>").append(date, title);
              var titlewrapper = $("<div class='link-box news-type'>").append(titlewrapperInner);
              var holder = $("<div class='item vidCard col-sm-12'>").append(link, titlewrapper);

              $("#youtube-sidebar").append(holder);
              //}

            }


            $('.pagination-owsd-sidebar').empty();
            if (prevToken !== undefined) {
              let paginationFirst = '<li data-uploads="' + uploads + '" data-token="' + prevToken + '"class="yt-page-item-sidebar prevToken">‹ prev</li>';
              $('.pagination-owsd-sidebar').append(paginationFirst);
            }

            if (nextToken !== undefined) {
              let paginationLast = '<li data-uploads="' + uploads + '" data-token="' + nextToken + '"class="yt-page-item-sidebar nextToken">next ›</li>';
              $('.pagination-owsd-sidebar').append(paginationLast);
            }
            prevNextYt();
          }
        });
      }

      function prevNextYt() {
        $('.yt-page-item-sidebar').click(function () {
          var token = $(this).attr('data-token');
          var uploads = $(this).attr('data-uploads');
          getVideos(uploads, token);
        });
      }
      prevNextYt();
    } //end youtube

    function responsiveTablesCnt() {
      var hasHeightClass = $('article.page table').parent().hasClass('table-responsive');
      console.log(hasHeightClass);
      $('article.page table').each(function(index, item) {
        if(!hasHeightClass) {
          $(item).addClass('table table-hover table-condensed table-bordered');
          $(item).wrap('<div class="table-responsive"></div>');
        }
      });
    }
    responsiveTablesCnt();


    /* on load */
    $(window).load(function () {

    });


    /* on resize */
    $(window).resize(function () {
      header();
      eqh();
      mapNavs();
      //regionsMembers();
      //regionsMembers2();
      ncHeight();
      var clicks = 0;
      $('.accordion-button.icon-hat').click(function () {
        if (clicks == 0) {
          galleryOther();
        }
        ++clicks;
        //console.log(clicks);
      });
      channelIframe();
    });
  });



})(jQuery);

}.call(window, window);